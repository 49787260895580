<template>
    <div class="project">
        <common-search-bar
            ref="searchBar"
            @change="handleSearch"
            :showProject="true"
        ></common-search-bar>
        <div class="list-container">
            <div class="list" v-if="arr.length">
                <div
                    class="item"
                    :class="[
                        isProject ? 'column-item' : '',
                        isCompetition ? 'sm-column-item' : ''
                    ]"
                    v-for="(item, idx) in arr"
                    :key="item._id"
                >
                    <div class="img-container">
                        <img
                            class="item-img"
                            alt=""
                            v-real-img="item.cover.src"
                        />
                        <Icon
                            @click="goTo(item._id)"
                            class="video-play-icon"
                            type="logo-youtube"
                            v-if="item.video || idx === 0"
                        />
                        <div v-if="isProject" class="item-info">
                            <div
                                class="item-title ellipse click-op"
                                @click="goTo(item._id)"
                            >
                                {{ item.title }}
                            </div>
                            <div class="item-desc row2-ellipse">
                                {{ item.excerpt }}
                            </div>
                            <div class="item-time">{{ item.postAt }}</div>
                        </div>
                    </div>
                    <div class="item-container">
                        <template v-if="!isProject">
                            <div class="item-time">{{ item.postAt }}</div>
                            <div
                                class="item-title ellipse click-op"
                                @click="goTo(item._id)"
                            >
                                {{ item.title }}
                            </div>
                            <div class="item-desc row2-ellipse">
                                {{ item.excerpt }}
                            </div>
                        </template>
                        <div class="tag-container">
                            <div class="tag-title">{{ "标签" | language }}</div>
                            <div class="tag-list">
                                <div
                                    class="tag-item"
                                    :class="{ select: row == tag }"
                                    v-for="row in item.tags"
                                    :key="row"
                                >
                                    {{ row }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <error :text="'没有符合筛选条件的数据~'" v-else></error>
            <div class="flex"></div>
            <Page
                :total="total"
                :current="pageNum"
                :page-size="pageSize"
                @on-change="handlePageChange"
                style="margin-top:40px;margin-bottom:40px;"
                show-total
            >
                <span class="ivu-page-total" style="font-size:16px;"
                    >{{ "共有" | language }} {{ total }}
                    {{ "条" | language }}</span
                >
            </Page>
        </div>
    </div>
</template>

<script>
import Error from "../../compontents/Error.vue";
import CommonSearchBar from "../../compontents/information/CommonSearchBar.vue";
export default {
    components: {
        CommonSearchBar,
        Error
    },
    props: {
        type: { type: String, default: "project" }
    },
    computed: {
        isProject() {
            return this.type === "project";
        },
        isCompetition() {
            return this.type === "competition";
        }
    },
    data() {
        return {
            year: "",
            tag: "",
            keyword: "",
            pageSize: 10,
            pageNum: 1,
            total: 0,
            arr: []
        };
    },

    methods: {
        goTo(id) {
            if (this.type == "project") {
                this.$router.push(
                    `/base/achievement/project/detail/${id}?type=${this.tag}`
                );
            } else {
                this.$router.push(
                    `/base/achievement/competition/detail/${id}?type=${this.tag}`
                );
            }
        },
        handleSearch(val) {
            let { keyword, year, tag } = val;
            if (
                keyword == this.keyword &&
                year == this.year &&
                tag == this.tag
            ) {
                return;
            }
            this.keyword = keyword;
            this.year = year== 'all'?'':year;
            this.tag = tag;
            this.fetchData();
        },
        //分页
        handlePageChange(page) {
            this.pageNum = page;
            this.fetchData();
        },
        async fetchData() {
            let res = await this.$http.get(
                `/achievement?tag=${this.tag}&type=${this.type}&year=${
                    this.year
                }&keyword=${this.keyword}&pageSize=${
                    this.pageSize
                }&pageNum=${this.pageNum - 1}`
            );

            this.arr = res.result;
            this.total = res.count;
        }
    },
    watch: {
        $route(val) {
            this.handlePageChange(1);
        }
    }
};
</script>

<style lang="less" scoped>
.project {
    .list-container {
        display: flex;
        flex-direction: column;
        min-height: 1000px;

        .list {
            .item {
                margin-top: 40px;
                display: flex;
                .img-container {
                    width: 300px;
                    height: 200px;
                    border-radius: 2px;
                    overflow: hidden;
                    position: relative;
                    .item-img {
                        width: 100%;
                        height: 100%;
                        border-radius: 2px;
                        object-fit: cover;
                        transition: all ease 0.3s;
                        &:hover {
                            transform: scale(1.1);
                        }
                    }
                }

                .item-container {
                    flex: 1;
                    margin-left: 20px;
                    .tag-container {
                        display: flex;
                        align-items: flex-start;
                        margin-top: 20px;
                        .tag-title {
                            width: 32px;
                            height: 35px;
                            font-size: 16px;
                            font-weight: 600;
                            color: rgba(0, 0, 0, 0.3);
                            line-height: 35px;
                        }
                        .tag-list {
                            margin-left: 20px;
                            flex: 1;
                            display: flex;
                            align-items: center;
                            flex-wrap: wrap;
                            .tag-item {
                                margin-right: 20px;
                                padding: 0 20px;
                                height: 32px;
                                border-radius: 2px;
                                border: 1px solid rgba(0, 145, 255, 1);
                                font-size: 12px;
                                font-weight: 400;
                                color: rgba(0, 145, 255, 1);
                                line-height: 32px;
                                margin-bottom: 20px;
                            }
                            .select {
                                background: rgba(0, 145, 255, 1);
                                color: #fff;
                            }
                        }
                    }
                }
                .item-title {
                    margin-bottom: 15px;
                    text-align: left;
                    height: 32px;
                    font-size: 22px;
                    font-weight: 600;
                    color: rgba(0, 0, 0, 0.9);
                    line-height: 32px;
                }
                .item-desc {
                    text-align: left;
                    height: 66px;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 32px;
                }
                .item-time {
                    text-align: left;
                    margin-bottom: 10px;
                    height: 24px;
                    font-size: 14px;
                    color: #000;
                    opacity: 0.3;
                    line-height: 24px;
                }
            }
            .column-item {
                flex-direction: column;
                .img-container {
                    width: 100%;
                    height: 300px;
                }
                .item-info {
                    background-color: rgba(0, 0, 0, 0.3);
                    position: absolute;
                    width: 100%;
                    padding: 10px 20px;
                    left: 0;
                    bottom: 0;
                    text-align: left;
                    color: #fff;
                    .item-title {
                        color: #fff;
                        margin-bottom: 0;
                        display: inline-block;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                    .item-desc {
                        text-align: left;
                        height: unset;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                    }
                    .item-time {
                        color: #fff;
                        opacity: 1;
                        margin-bottom: 0;
                        height: unset;
                        margin-top: 5px;
                    }
                }
            }
        }
        .flex {
            flex: 1;
        }
    }
}
@media screen and (max-width: 960px) {
    .project {
        .list-container {
            .list {
                .item {
                    margin-top: 30px;
                    .item-container {
                        margin-left: 0;
                        .tag-container {
                            .tag-list {
                                .tag-item {
                                    margin-bottom: 10px;
                                    margin-right: 10px;
                                }
                            }
                        }
                    }
                }
                .sm-column-item {
                    flex-direction: column;
                    align-items: center;
                    .img-container {
                        width: 100%;
                    }
                    .item-container {
                        margin-top: 10px;
                        .item-time {
                            line-height: 1;
                            height: auto;
                        }
                        .item-title {
                            font-size: 18px;
                            font-weight: 600;
                            height: auto;
                            line-height: 1;
                            margin-bottom: 10px;
                        }
                        .item-desc {
                            line-height: 1.5;
                            height: auto;
                            font-size: 14px;
                        }
                        .tag-container {
                            .tag-list {
                                margin-left: 10px;
                                .tag-item {
                                    padding: 6px 11px;
                                    height: auto;
                                    line-height: 1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
